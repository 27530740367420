
<script setup >
// import SvgLogo from "@/assets/images/logo_web_01.svg?raw";
let SvgLogo = import.meta.glob('@/assets/images/logo_web_01.svg', { query: '?raw', import: 'default',eager:true })
// const logo = await import(`@/assets/images/logo_web_01.svg?raw`)
//  console.log(SvgLogo['/assets/images/logo_web_01.svg'],"----")

</script>

<template>
    <div class="aj-logo" v-html="SvgLogo['/assets/images/logo_web_01.svg']" >

    </div>
</template>
<style lang="scss" >
    .aj-logo{
    //    max-height: 100%;
    height: 100%;
    
        svg{
           fill:inherit;
           height:100%;
           width: auto;
           max-width: 100%;
        }
    }
    
</style>